import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// import Swiper core and required modules
import { Navigation, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import { WhyUs } from '../../APIs/WhyUs';
import Card from '../cards/whyChooseUsCards/Card';
import './whyChooseUs.css'

export default function WhyChooseUs() {
    return (
        <div className='why-choose-us'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 one">
                        <h5 className="sub-header text-light">Why choose us</h5>
                        <h3 className="main-header text-light">20+ clients love our service & IT related solution</h3>
                    </div>
                    <div className="col-lg-8 col-md-12">
                        <div className="row why-choose-us-card">
                            <Swiper
                                // install Swiper modules
                                modules={[Navigation, Autoplay]}
                                navigation
                                loop={true}
                                initialSlide={2}
                                autoplay={{
                                    delay: 10000,
                                    disableOnInteraction: false,
                                }}
                                breakpoints=
                                {{
                                    450: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    640: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    768: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    1024: {
                                        slidesPerView: 2,
                                        spaceBetween: 50,
                                    },
                                }}
                            >
                                {
                                    WhyUs.map(d => (
                                        <SwiperSlide key={d.id} id='img'>
                                            <Card
                                                img={d.img}
                                                name={d.name}
                                                text={d.text}
                                                position={d.position}
                                                title={d.title}
                                            />
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
