import './about.css';
import { Link } from 'react-router-dom';
import WhoWeAre from './../../component/whoWeAre/WhoWeAre';
import Project from '../../component/projectsNum/Project';
import Clients from '../../component/clients/Clients';
import Experience from './../../component/experience/Experience';
import ServiceCard from '../../component/servicecards/ServiceCard';
import MainContactForm from '../../component/contact/contactForm/mainContactForm/MainContactForm';
import ContactDetailsForm from '../../component/contact/contactDetails/ContactDetailsForm';

export default function About() {
    return (
        <div className="about">
            <div className="container-fluid">
                <div className="row">
                    <div className="hero">
                        <div className='shape-1'></div>
                        <div className="content">
                            <h1 className="heading">about us</h1>
                            <div className="links">
                                <p><Link className='link' to="/">home</Link> / about us</p>
                            </div>
                        </div>
                    </div>
                    <WhoWeAre />
                    <div className="position">
                        <ServiceCard />
                    </div>
                    <Experience />
                    <Project />
                    <div className="contact-form-forms">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5 col-sm-12">
                                    <MainContactForm />
                                </div>
                                <div className="col-1"></div>
                                <div className="col-md-6 col-sm-12">
                                    <ContactDetailsForm />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Clients />
                </div>
            </div>
        </div>
    )
}
