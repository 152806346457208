import React from 'react';
import './blog.css'
import Card from '../cards/blogCard/Card';
import data from './blog.data';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


export default function Blog() {
   
    return (
        <div className='blog'>
            <div className="container">
                <div className="row">
                    <h4 className="sub-header-center">news & blog</h4>
                    <div className="main-header-center">Trending article & stories</div>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation]}
                        navigation
                        loop={true}
                        initialSlide={2}
                        breakpoints=
                        {{
                            450: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            768: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            1024: {
                                slidesPerView: 2.8,
                                spaceBetween: 50,
                            },
                        }}
                    >
                        {
                            data ? data.map(item => (
                                <SwiperSlide className="col-lg-4 col-md-6 mb-3" key={item.id}>
                                    <Card
                                        img={item.img}
                                        type={item.type}
                                        text={item.text}
                                        comments={item.comments}
                                        title={item.title}
                                        link={item.link}
                                        writer={item.writer}
                                    />
                                </SwiperSlide>
                            )) : null
                        }
                    </Swiper>
                </div>
            </div>
        </div>
    )
}
