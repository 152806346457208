import React from 'react';
import './whyUs.css'

export default function WhyUs() {
    return (
        <div className='why-us'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <h5 className="sub-header">why choose us</h5>
                        <h3 className="main-header">We provide truly prominent IT solutions for your success </h3>
                        <div className="our-content">
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="row">
                                        <div className="col-md-3 col-sm-12">
                                            <i className="fa fa-industry" aria-hidden="true"></i>
                                        </div>
                                        <div className="col-md-9 col-sm-12">
                                            <h4 className="heading">Expert Team</h4>
                                            <p className="text-dark">Accelerate innovation with world-class tech teams</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-sm-12">
                                            <i className="fa fa-microchip" aria-hidden="true"></i>
                                        </div>
                                        <div className="col-md-9 col-sm-12">
                                            <h4 className="heading">quality control</h4>
                                            <p className="text-dark">Accelerate innovation with world-class tech teams</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="row">
                                        <div className="col-md-3 col-sm-12">
                                            <i className="fa fa-lock" aria-hidden="true"></i>
                                        </div>
                                        <div className="col-md-9 col-sm-12">
                                            <h4 className="heading">integration</h4>
                                            <p className="text-dark">Accelerate innovation with world-class tech teams</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-sm-12">
                                            <i className="fa fa-comments" aria-hidden="true"></i>
                                        </div>
                                        <div className="col-md-9 col-sm-12">
                                            <h4 className="heading">24/7 support</h4>
                                            <p className="text-dark">Accelerate innovation with world-class tech teams</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
