import React from 'react'
import { ServiceCards } from '../../APIs/ServiceCards';
import Card from '../cards/serviceCards/Card';
import './service.css'

export default function ServiceCard() {
    return (
        <div className="service-card">
            <div className="container">
                <div className="row">
                    {
                        ServiceCards ? ServiceCards.map(item => (
                            <div className="col-lg-3 col-md-6" key={item.id}>
                                <Card
                                    img={item.img}
                                    name={item.name}
                                    text={item.text}
                                />
                            </div>
                        )) : null
                    }
                </div>
            </div>
        </div>
    )
}
