import React from 'react';
import Counter from '../counter/Counter';
import './project.css';


export default function Project() {
    return (
        <div className='project'>
            <div className="container">
                <div className="row">
                    <div className="project col-md-6 col-sm-12">
                        <div className="icon"><i className="fa fa-lightbulb-o" aria-hidden="true"></i></div>
                        <h3 className="num">
                            <Counter
                                end={20}
                                num={20}
                            /> +
                        </h3>
                        <p className="texts">Completed Projects</p>
                    </div>
                    <div className="project col-md-6 col-sm-12">
                        <div className="icon"><i className="fa fa-handshake-o" aria-hidden="true"></i></div>
                        <h3 className="num"><Counter
                            end={10}
                            num={10}
                        /> +
                        </h3>
                        <p className="texts">Satisfied clients</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
