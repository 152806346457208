import React from 'react'
import Progress from '../progressBars/Progress';
import './experience.css';

export default function Experience() {
    return (
        <div className='experience'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-4 col-sm-12 side">
                        <div className="years">
                            <h2 className="num">10+</h2>
                            <p className="years-text">Years of experience</p>
                            <p className="info-text">We run all kinds of <span>IT services</span> that vow your success</p>
                        </div>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-lg-6 col-md-7 col-sm-12 side-text">
                        <h3 className="header-two">Accelerate innovation with world-class technical teams that can integrate seamlessly into existing structures or help you build new structures.</h3>
                        <Progress />
                    </div>
                </div>
            </div>
        </div>
    )
}
