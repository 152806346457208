import React from 'react';
import easei from '../../Media/EaseiLogo_WithText.png'
import justech from '../../Media/justech.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';

import 'swiper/css';
import './client.css'

export default function Clients() {
    const data = [
        {
            id: 1,
            img: easei,
            name: easei
        },
        {
            id: 2,
            img: justech,
            name: justech
        },
        {
            id: 3,
            img: easei,
            name: easei
        },
        {
            id: 4,
            img: justech,
            name: justech
        }
    ]

    return (
        <div className='clients'>
            <div className="container">
                <div className="row">
                    <h5 className="heading">The organizations you trust, trust us.</h5>

                    <Swiper
                        modules={[Autoplay]}
                        loop={true}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        breakpoints=
                        {{
                            450: {
                                slidesPerView: 1,
                                spaceBetween: 5,
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 10,
                            },
                            1024: {
                                slidesPerView: 5,
                                spaceBetween: 10,
                            },
                        }}
                    >
                        {
                            data.map(d => (
                                <SwiperSlide key={d.id} id='img'>
                                    <img src={d.img} alt={d.name} />
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>
            </div>
        </div>
    )
}
