import one from '../Media/5.png';
import two from '../Media/6.png';
import userImage from '../../src/Media/BUS6745.jpg'

export const WhyUs = [
    {
        "id": 1,
        "img": one,
        "userImage": userImage,
        "title": "Perfect Service",
        "position": "CEO, Justech Engineering Consult",
        "name": "Justin Anigbogu",
        "text": "Their services were exceptional, they helped my organization into the digital space effortlessly"
    },
    {
        "id": 2,
        "img": two,
        "userImage": userImage,
        "title": "Simply Excellent",
        "position": "Product Designer, Easei",
        "name": "Ozulaku Ezinne",
        "text": "Their contributions and execution positively revolutionalized the prospects of our product"
    }
]