export const ProgressInfo = [
    {
        "id": 1,
        "info": "Software Development",
        "percent": "98%",
        "now": 98
    },
    {
        "id": 2,
        "info": "Data & Security",
        "percent": "99%",
        "now": 99
    },
    {
        "id": 3,
        "info": "Cloud Management & Devops",
        "percent": "90%",
        "now": 90
    },
    {
        "id": 4,
        "info": "Project Management",
        "percent": "90%",
        "now": 90
    },
    {
        "id": 5,
        "info": "UI/UX",
        "percent": "90%",
        "now": 90
    }
]