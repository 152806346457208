import React from 'react'
import './card.css'

export default function Card({ img, name, text }) {
    return (
        <div className='service-cards'>
            <div className="container">
                <div className="row">
                    <div className="card">
                        <div className="contents">
                            <img src={img} className="img-fluid" alt={name} />
                            <div className="card-body">
                                <h3 className="card-title">{name}</h3>
                                <p className="card-text">{text}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
