import SDLC from "../../Media/SDLC.svg";
import Leader from "../../Media/Leader.jpg";
import Beyond from "../../Media/beyond.jpeg";
import Approved from "../../Media/approved.jpg";

const data = [
  {
    id: 1,
    img: SDLC,
    type: "Software Engineering",
    writer:"John Kehinde",
    // comments: 2,
    title: "The impact of Automated testing on SDLC",
    text: "Automated testing is a process of using software tools to execute predefined test cases on a software application...",
    link: "https://blazortechnologies.medium.com/the-impact-of-automated-testing-on-software-development-life-cycle-sdlc-2874b61aecb3",
  },
  {
    id: 2,
    img: Beyond,
    type: "Technology",
    writer:"Chibuikem Akpaka",
    // comments: 2,
    title: "Offshore teams (Getting it right beyond technology)",
    text: "In the dynamic and ever-evolving landscape of modern global business, offshore teams have risen to prominence as an essential ingredient for achieving triumph...",
    link: "https://blazortechnologies.medium.com/navigating-success-beyond-technology-mastering-offshore-teams-for-seamless-collaboration-d5da953fe9ba",
  },
  {
    id: 3,
    img: Approved,
    type: "Quality Assurance",
    writer:"Arinze Osakwe",
    // comments: 2,
    title: "Need for Software Quality Assurance in Software Development.",
    text: "It is one thing to build a software application and it is another thing to ensure that the application works the way it is supposed to...",
    link: "https://blazortechnologies.medium.com/need-for-software-quality-assurance-in-software-development-d92be26d071e",
  },
  {
    id: 4,
    img: Leader,
    type: "Leadership and Management",
    writer:"John Kehinde",
    // comments: 2,
    title: "IT Leaders: Are You Ready for These 8 Challenges?",
    text: "As a seasoned IT leader, the responsibility of safeguarding your organization's technological landscape while ensuring its efficiency and reliability rests on your shoulders. Yet...",
    link: "https://blazortechnologies.medium.com/navigating-the-it-landscape-overcoming-8-crucial-challenges-for-it-leaders-2384df4fe200",
  }
];
export default data;
