import React from 'react';
import banner from '../../Media/blazorTech.jpg'
import './who.css'

export default function WhoWeAre() {
    return (
        <div className="who">
            <div className='container'>
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <img
                            alt='Banner'
                            src={banner}
                            className='img-fluid'
                        />
                    </div>
                    <div className="col-lg-5 col-sm-12 two">
                        <h4 className="sub-header">who we are</h4>
                        <h3 className="header-one">We specialise in helping our customers digitise their business</h3>
                        <p className="text">Accelerate innovation with world-class tech teams We’ll match you to an entire remote team of incredible freelance talent for all your software development needs.</p>
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <ul className="attributes">
                                    <li className="list">Solving complex problems</li>
                                    <li className="list">We guarantee trusted service</li>
                                    <li className="list">Experts in technology fields</li>
                                </ul>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <ul className="attributes">
                                    <li className="list">Business oriented solutions</li>
                                    <li className="list">Global Delivery Model</li>
                                    <li className="list">Sure to exceed your expectations</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
