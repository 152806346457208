import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from './component/footer/Footer';
import Home from "./pages/home/Home";
import Navbar from "./component/navbar/Navbar";
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import Services from "./pages/services/Services";
import BackToTop from "./component/backToTop/BackToTop";
import ScrollToTop from "./component/scrollPageToTop/ScrollToTop";
import Testimonial from './pages/testimonial/Testimonial';

import './App.css';


function App() {
  useEffect(() => {
    const body = document.querySelector('#root');

    body.scrollIntoView({
      behavior: 'smooth'
    }, 500)
  }, []);

  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/testimonial" element={<Testimonial />} />
        </Routes>
        <Footer />
      </Router>
      <BackToTop />
    </div>
  );
}

export default App;
