import './contactForm.css';
import { BaseDetails } from '../../../../APIs/Constants/constants'

export default function MainContactForm() {
    return (
        <div className="main-contact-form">
            <div className='container'>
                <div className="row">
                    <h2 className="main-heading">need a quick query?</h2>
                    <div className="col-12 content">
                        <div className="row">
                            <div className="col-lg-2 col-md-3 col-sm-12">
                                <i className="fa fa-phone" aria-hidden="true"></i>
                            </div>
                            <div className="col-lg-10 col-md-9 col-sm-12">
                                <h3 className="heading">Contact Number</h3>
                                <p className="sub-heading">{ BaseDetails.PhoneNumber }</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 content">
                        <div className="row">
                            <div className="col-lg-2 col-md-3 col-sm-12">
                                <i className="fa fa-envelope-o" aria-hidden="true"></i>
                            </div>
                            <div className="col-lg-10 col-md-9 col-sm-12">
                                <h3 className="heading"> our mail</h3>
                                <p className="sub-heading">{ BaseDetails.Email }</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 content">
                        <div className="row">
                            <div className="col-lg-2 col-md-3 col-sm-12">
                                <i className="fa fa-map-marker" aria-hidden="true"></i>
                            </div>
                            <div className="col-lg-10 col-md-9 col-sm-12">
                                <h3 className="heading">our location</h3>
                                <p className="sub-heading">{ BaseDetails.Address }</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
