import "./card.css"

export default function Card({ icon, name, info }) {
    return (
        <div>
            <section className='contact-card'>
                <div className='container'>
                    <div className='row'>
                        <div className="card">
                            <div className="icon">
                                <i className={icon} aria-hidden="true"></i>
                            </div>
                            <div className="card-body text-center">
                                <h3 className="card-title">{name}</h3>
                                <h3 className="card-info">{info}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
