import React from 'react'
import { Link } from 'react-router-dom'
import Blog from '../../component/blog/Blog'
import Card from '../../component/cards/services/Services'
import Specialty from '../../component/specialty/Specialty'
import './services.css'

export default function Project() {
    const data = [
        {
            id: 1,
            icon: "fa fa-laptop",
            name: "Software Development",
            info: "First class technology solutions tailored to your unique enterprise challenges."
        },
        {
            id: 2,
            icon: "fa fa-microchip",
            name: "Cloud Management & Devops",
            info: "From Cloud Migration to Continuous delivery to Infrastructure assessment and monitoring we got you covered"
        },
        {
            id: 3,
            icon: "fa fa-search",
            name: "Business Analytics",
            info: "Make data driven decisions. We can help you build products that leverage your data to determine trends and forecast your future"
        },
        {
            id: 4,
            icon: "fa fa-paper-plane-o",
            name: "Product Management and Design",
            info: "Wowing your customers and organizing your team. These are the things we do for fun."
        }
    ]

    return (
        <div className="services">
            <div className="container-fluid">
                <div className="row">
                    <div className="hero">
                        <div className='shape-1'></div>
                        <div className="content">
                            <h1 className="heading"> services </h1>
                            <div className="links">
                                <p><Link className='link' to="/">home</Link> / services</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="service row">
                    <h2 className="sub_heading">We run all kinds of <span> IT Services </span> that vow your success </h2>
                </div>
                <div className="row services-cards">
                    {
                        data ? data.map(item => (
                            <div className="col-lg-6 col-md-12 mb-5" key={item.id}>
                                <Card
                                    icon={item.icon}
                                    name={item.name}
                                    info={item.info}
                                />
                            </div>
                        )) : null
                    }
                </div>
            </div>
            <Specialty />
            <div className="request">
                <h2 className="heading">Make a great project with us</h2>
                <p className="enquire">To make requests for further information, <Link className='link' to="/contact">contact us</Link>
                </p>
            </div>
            <Blog />
        </div>
    )
}
