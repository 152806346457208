import { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

export default function Counter({ end, num, ...rest }) {
    const [count, setCount] = useState(false)
    return (
        <div className='counter'>
            <div className="counter-column">
                <strong data-number={num}>
                    <CountUp {...rest} start={count ? null : 0} end={end}>
                        {({ countUpRef }) => {
                            return (
                                <VisibilitySensor active={!count}
                                    onChange={isVisible => {
                                        if (isVisible) {
                                            setCount(true);
                                        }
                                    }}
                                    delayedCall
                                >
                                    <span ref={countUpRef} />
                                </VisibilitySensor>
                            )
                        }}
                    </CountUp>
                </strong>
            </div>
        </div>
    )
}
