import "./card.css"

export default function Card({ img, name, position, text }) {
    return (
        <section className='testimonial-card'>
            <div className='container'>
                <div className='row'>
                    <div className="col-lg-3 col-md-12">
                        <img src={img} className="img-fluid" alt={name} />
                    </div>
                    <div className="col-lg-9 col-md-12">
                        <h4 className="heading">{text}</h4>
                        <h6 className="name">{name}</h6>
                        <p className="designation">{position}</p>
                    </div>
                </div>
            </div>
        </section >
    )
}
