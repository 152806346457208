import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../Media/blazorLogo.png';
import './navbar.css'
import { BaseDetails } from '../../APIs/Constants/constants';


class Navbar extends Component {
    state = {
        clicked: false,
        visible: false
    };

    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }

    handleChangeSearch = () => {
        this.setState({ clicked: !this.state.clicked, visible: !this.state.visible })
        console.log("clicked search");

    }
    render() {
        return (
            <div className="Navbar">
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container d-flex flex-nowrap">
                        <button className="navbar-toggler"
                            type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"

                            onClick={this.handleClick}
                        >
                            <i id="bar" className={this.state.clicked ? "fa fa-bars" : "fa fa-bars"}></i>
                        </button>
                        <Link to="/" className='text-uppercase navbar-brand'>
                            <img src={logo} alt="logo" className='img-fluid' />
                        </Link>
                        <div className="space"></div>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <Link className='nav-link' to="/about">about</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className='nav-link' to="/contact">contact</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className='nav-link' to="/services">services</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className='nav-link' to="/testimonial">testimonial</Link>
                                </li>
                            </ul>
                            <button className="navbar-toggler"
                                type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={this.handleClick}>
                                <i id="bar2" className={this.state.clicked ? "fa fa-times" : "fa fa-times"} ></i>
                            </button>
                            <div className="contact_search">
                                <div className="call d-flex">
                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                    <div className="">
                                        <p className="text">free consultation</p>
                                        <h6 className="num"><a href={`tel:${BaseDetails.PhoneNumber}`} className='link'>{BaseDetails.PhoneNumber}</a></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        )
    }
}

export default Navbar;
