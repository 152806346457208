import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css';
import logo from '../../Media/blazorLogo.png';
import FooterColophon from './FooterColophon';
import { BaseDetails } from '../../APIs/Constants/constants';

export default function Footer() {
    const showMap = (e) => {
        e.preventDefault();
        window.open("https://maps.google.com?q=" + 6.59651 + "," + 3.34205);
    };
    return (
        <div className='footer' id='support'>
            <FooterColophon />
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-sm-12" style={{marginTop:"5.5rem"}}>
                        <Link to="/">
                            <img src={logo} alt="logo" className='img-fluid' />
                        </Link>
                    </div>
                    <div className="col-md-4 col-sm-6 links">
                        <h3 className="heading">our contact</h3>
                        <a href={`tel:${BaseDetails.PhoneNumber}`} className="text"><span><i className="fa fa-phone" aria-hidden="true"></i></span> {BaseDetails.PhoneNumber}</a>
                        <a href={`mailto:${BaseDetails.Email}`} className="text"><span><i className="fa fa-envelope" aria-hidden="true"></i></span> { BaseDetails.Email}</a>
                        <a href='/' className="text" onClick={showMap}><span><i className="fa fa-map-marker" aria-hidden="true"></i></span>{BaseDetails.Address}</a>
                    </div>
                    <div className="col-md-4 col-sm-6 links">
                        <h3 className="heading">useful links</h3>
                        <ul>
                            <li><Link className='footer-link' to="/about">about us</Link></li>
                            <li><Link className='footer-link' to="/contact">contact us</Link></li>
                            <li><Link className='footer-link' to="/services">our services</Link></li>
                            <li><Link className='footer-link' to="/testimonial">testimonials</Link></li>
                        </ul>
                    </div>
                    <div className="copyright">
                        <div className="">
                            <p className="text"> © Copyright 2023, Blazor Technologies. All rights reserved.</p>
                        </div>
                        <div className="socials">
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                            <i className="fa fa-linkedIn" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
