import React from 'react';
import { Link } from 'react-router-dom';

import './card.css'

export default function Card({ img, title, text, type, link, writer }) {
    return (
        <div className='blog-card'>
            <div className="card">
                <img src={img} alt={type} className='card-img-top' />
                <span className="type">{type}</span>
                <div className="card-body">
                    <div className="organization">
                        <p className="name"> <span><i className="fa fa-user" aria-hidden="true"></i></span> {writer}</p>
                        {/* <p className="comments"> <span><i className="fa fa-comments-o" aria-hidden="true"></i></span> {comments} Comments </p> */}
                    </div>
                    <h5 className="card-title"><Link to="">{title}</Link></h5>
                    <p className="card-text">{text}</p>
                </div>
                <div className="card-footer">
                    <a href={link} target="_blank" rel="noopener noreferrer" className='read-more'> read more</a>
                    <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                </div>
            </div>
        </div>
    )
}
