import { Link } from "react-router-dom"
import "./services.css"

export default function Card({ icon, name, info }) {
    return (
        <div>
            <section className='services-card'>
                <div className='container'>
                    <div className='row'>
                        <div className="card">
                            <div className="icon">
                                <i className={icon} aria-hidden="true"></i>
                            </div>
                            <div className="card-body">
                                <h3 className="card-title">
                                    <Link className='link' to="">{name}</Link>
                                </h3>
                                <p className="card-info">{info}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
