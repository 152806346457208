import React from 'react'
import { ProgressBar } from 'react-bootstrap';
import { ProgressInfo } from '../../APIs/ProgressInfo';
import './progress.css'

export default function Progress() {

    return (
        <div className="progressbar">
            {ProgressInfo ? ProgressInfo.map(item => (
                <div key={item.id} className="single-progress">
                    <div className="d-flex justify-content-between">
                        <h3 className='info'>{item.info}</h3>
                        <h3 className="percent">{item.percent}</h3>
                    </div>
                    <ProgressBar now={item.now} animated style={{ height: "10px" }} />
                </div>
            )
            ) : null}
        </div>
    )
}
