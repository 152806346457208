import React from 'react'
import { Link } from 'react-router-dom'
import Card from '../../component/cards/testimonialCard/Card'
import WhyChooseUs from '../../component/whyChooseUs/WhyChooseUs';
import './testimonial.css'

import { Swiper, SwiperSlide } from 'swiper/react';

// import Swiper core and required modules
import { Pagination } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { WhyUs as data } from '../../APIs/WhyUs';
import Clients from './../../component/clients/Clients';


export default function Testimonial() {

    return (
        <div className="testimonial">
            <div className="container-fluid">
                <div className="row">
                    <div className="hero">
                        <div className='shape-1'></div>
                        <div className="content">
                            <h1 className="heading">Testimonial</h1>
                            <div className="links">
                                <p><Link className='link' to="/">home</Link> / testimonial</p>
                            </div>
                        </div>
                    </div>
                    <Swiper
                        // install Swiper modules
                        modules={[Pagination]}
                        loop={true}
                        initialSlide={2}
                        pagination={{
                            clickable: true
                        }}
                    >
                        {
                            data.map(d => (
                                <SwiperSlide key={d.id}>
                                    <Card
                                        img={d.userImage}
                                        name={d.name}
                                        text={d.text}
                                        position={d.position}
                                    />
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                    <WhyChooseUs />
                    <Clients />
                </div>
            </div>
        </div>
    )
}
