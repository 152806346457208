import React from 'react'
import { Link } from 'react-router-dom'
import './footer.css'
import { BaseDetails } from '../../APIs/Constants/constants'

export default function FooterColophon() {
    return (
        <div className='colophon'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-7 col-sm-12">
                        <h3 className="heading">Let's create something Extraordinary together</h3>
                    </div>
                    <div className="col-md-5 col-sm-12">
                        <div className="col-md-6 col-sm-12">
                            <h4 className="num">{BaseDetails.PhoneNumber}</h4>
                        </div>
                        <div className="col-md-6 col-sm-12 dark">
                            <Link to="/services" className='btn' id='dark'>Our Services </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
