import React from 'react'
import Button from '../../button/Button'
import './contactDetailsForm.css'

export default function ContactDetailsForm() {
    return (
        <div className='contact-details-form'>
            <div className="container">
                <form action="">
                    <div className="row">
                        <div className="headers">
                            <h4 className="sub-heading">leave us a message</h4>
                            <h2 className="heading">how may we help you?</h2>

                        </div>
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <input type="text" className="form-control" placeholder="Your Name" aria-label="Name" />
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <input type="email" className="form-control" placeholder="Email" aria-label="Email" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <input type="number" className="form-control" placeholder="Phone" aria-label="Phone" />
                            </div>
                            <div className="col-12">
                                <textarea className="form-control" rows="3" placeholder='Message...'></textarea>
                            </div>
                            <div className="col-12">
                                <Button name="Send Message" id="btn-block" />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
