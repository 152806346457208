import banner from '../../Media/blazorTech.jpg'
import Progress from '../progressBars/Progress';
import './specialty.css'

export default function Specialty() {
    return (
        <div className='specialty'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-12 col-sm-12">
                        <img
                            alt='Banner'
                            src={banner}
                            className='img-fluid'
                        />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 side-text">
                        <h2 className="info_heading">We specialise in helping our <span> customers digitise</span> their business </h2>
                        <Progress />
                    </div>
                </div>
            </div>
        </div>
    )
}
