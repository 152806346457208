import one from '../Media/5.png';
import two from '../Media/6.png';
import three from '../Media/7.png';
import four from '../Media/8.png'

export const ServiceCards = [
    {
        "id": 1,
        "img": one,
        "name": "Strategy & Discovery",
        "text": "All you need to have is the idea, we will come up with the strategy and discover the requirements. You can literally fold your hands and watch us do the magic"
    },
    {
        "id": 2,
        "img": two,
        "name": "Software Solution",
        "text": "Over the years, our Engineers have built some of the most in demand software products in Africa and the works. So relax, you are in good hands."
    },
    {
        "id": 3,
        "img": three,
        "name": "Technology Solution",
        "text": "Some call us the technology mall, you can get everything here. UI/UX, Project Management, Consultancy, Software Development and more"
    },
    {
        "id": 4,
        "img": four,
        "name": "Business Analytics",
        "text": "We know you are building for business, so we always embed business intelligence and anayltics to all our solutions."
    }
]