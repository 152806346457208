import "./card.css"

export default function Card({ img, name, title, text, position }) {
    return (
        <section className='why-us-card'>
            <div className='container'>
                <div className='row'>
                    <div className="card">
                        <div className="card-body text-center">
                            <h2 className="card-title">{title}</h2>
                            <p className="card-text">{text}</p>
                            <div className="portfolio">
                                <img src={img} className="img-fluid" alt={name} />
                                <div className="info">
                                    <h4 className="name">{name}</h4>
                                    <h5 className="position">{position}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}
