import React from 'react';
import './contact.css';
import { Link } from 'react-router-dom';
import Card from '../../component/cards/contactCards/Card';
import ContactDetailsForm from '../../component/contact/contactDetails/ContactDetailsForm';
import MainContactForm from '../../component/contact/contactForm/mainContactForm/MainContactForm';
import Clients from '../../component/clients/Clients';
import { BaseDetails } from '../../APIs/Constants/constants';

export default function Contact() {
    const data = [
        {
            id: 1,
            icon: "fa fa-phone",
            name: "Telephone",
            info:  BaseDetails.PhoneNumber
        },
        {
            id: 2,
            icon: "fa fa-envelope-o",
            name: "Email Address",
            info: BaseDetails.Email
        },
        {
            id: 3,
            icon: "fa fa-map-marker",
            name: "Location",
            info: BaseDetails.Address
        }
    ]
    return (
        <div className="contact">
            <div className="container-fluid">
                <div className="row">
                    <div className="hero">
                        <div className='shape-1'></div>
                        <div className="content">
                            <h1 className="heading">Contact us</h1>
                            <div className="links">
                                <p><Link className='link' to="/">home</Link> / contact us</p>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row contact-cards">
                            {
                                data ? data.map(item => (
                                    <div className="col-lg-3 col-md-4" key={item.id}>
                                        <Card
                                            icon={item.icon}
                                            name={item.name}
                                            info={item.info}
                                        />
                                    </div>
                                )) : null
                            }
                        </div>
                    </div>
                    <div className="contact-form-forms">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5 col-sm-12">
                                    <MainContactForm />
                                </div>
                                <div className="col-1"></div>
                                <div className="col-md-6 col-sm-12">
                                    <ContactDetailsForm />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Clients />
                </div>
            </div >
        </div >
    )
}
