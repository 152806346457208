import React from 'react'
import './home.css'
import WhoWeAre from '../../component/whoWeAre/WhoWeAre';
import Experience from '../../component/experience/Experience';
import Project from '../../component/projectsNum/Project';
import WhyUs from '../../component/whyUs/WhyUs';
import WhyChooseUs from '../../component/whyChooseUs/WhyChooseUs';
import Clients from '../../component/clients/Clients';
import Blog from '../../component/blog/Blog';
import ServiceCard from '../../component/servicecards/ServiceCard';

export default function Home() {
    return (
        <div className="home">
            <div className="container-fluid">
                <div className="row">
                    <div className="home-hero">
                        <div className='shape-1'></div>
                        <div className="content">
                            <h2 className="sub-title">Technlology related development and consultancy</h2>
                            <h1 className="main-title">We bring great <br /> ideas to life</h1>
                            <p className="text">With a combined team experience spanning decades, we have learnt how to provide the most responsive and functional services in all aspects of IT</p>
                        </div>
                    </div>
                    <ServiceCard />
                    <WhoWeAre />
                    <Experience />
                    <Project />
                    {/* <Projects /> */}
                    <WhyUs />
                    <WhyChooseUs />
                    <Blog />
                    <Clients />
                </div>
            </div>
        </div>
    )
}
